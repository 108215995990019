
import {
    createApp
} from 'vue'
import App from './App.vue'
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.min.css'
import './style/color.scss'
import './style/fonts.scss'
import './style/icons.scss'
import './style/image.scss'
import './style/utilities.scss'
import router from './router'
import FlagIcon from 'vue-flag-icon'
// import SequentialEntrance from 'vue-sequential-entrance'
// import 'vue-sequential-entrance/vue-sequential-entrance.css'

import 'animate.css';
import i18n from './i18n';

createApp(App).use(i18n).use(router).use(FlagIcon).use(store).mount('#app')
