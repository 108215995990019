<!-- app talabtkom -->
<template>
  <!-- App Talabtkom div holder -->
  <div class="position-relative overflow-hidden" :class="$i18n.locale === 'ar' ? 'textend' : 'newdir'">
    <!-- container -->
    <div class="container px-0">
      <div class="text-center">
        <p class="fCC-SemiBold-sD cCC-text-secondary mt-5">
           {{ $t("HowDoes") }}
          <b class="cCC-text-main">{{ $t("Talabatcom") }} </b>
          
        </p>
        <p class="fCC-Meduim-sA cCC-opacity-secondary-70 cCC-text-secondary">
          {{ $t("TalabatcomOffers") }}
          <span class="d-block">  {{ $t("TalabatcomOffersand") }}</span>
        </p>
      </div>
      <!-- Row  -->
      <div
        class="row p-0 mb-5 mt-5"
        :class="user.rowDirection"
        
        v-for="user in AppUsers"
        :key="user"
      >
        <!-- Mobile IMG Col -->
        <div class="flex-column" >
          <!-- side icon -->
          <i
            class="
              position-absolute
              CC-icon-side
              mt-5
              imCC-Background-Contain
              wow
              animate__animated animate__delay-1s
            "
            :class="[user.icon, user.position, user.animation]"
          ></i>
        </div>
        <!-- mobile img -->
        <div
          class="col-md-6 col-12 d-flex mt-5 wow animate__animated"
          :class="user.mobreve"
        >
          <div
            class="
              uCC-border-h-50
              CC-border
              shadow-l
              p-3
              cCC-bg-main cCC-opacity-support-19
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <i
              :class="user.img"
              class="d-flex imCC-Background-Contain CC-mobile position-relative"
            ></i>
          </div>
        </div>

        <!-- Text Col -->
        <div
          class="
            col-md-6 col-12
            d-flex
            flex-column
            justify-content-center
            m-0
            mb-md-
            5
            uCC-gap-16
            CC-media-text
            align-items-end
            mt-5
            wow
            animate__animated
          "
          :class="user.animation"
        >
          <p class="fCC-SemiBold-sB cCC-text-secondary m-0 px-3">
            {{ $t(user.title) }}
          </p>
          <span class="CC-line cCC-bg-main mx-3" :class="user.line"></span>
          <p
            class="fCC-Meduim-saA cCC-text-secondary opacity-75 px-3"
             :class="$i18n.locale === 'en' ? 'textdir' : 'newdir'"
          >
            {{ $t(user.text) }}
          </p>
        </div>
      </div>
      <!-- App Icons -->
      <div
        class="
          CC-last-app-containt
          my-5
          d-flex
          flex-column
          uCC-gap-32-plus
          text-center
        "
      >
        <p
          class="
            fCC-Regular-sC
            uCC-gap-24
            CC-font
            cCC-text-secondary
            d-flex
            justify-content-center
          "
        >
     
          {{ $t("Appreciate") }}
        </p>
        <div
          class="
            CC-store-last-icon
            uCC-gap-16
            d-flex
            position-relative
            w-100
            justify-content-center
          "
        >
          <div class="flex-row" v-for="i in newSocialMedia" :key="i">
            <a :href="i.link" target="_blank">
              <i
                :class="[i.icon]"
                class="
                  d-flex
                  imCC-Background-Contain
                  CC-icons-header
                  uCC-Click
                  wow
                  animate__animated
                  animation
                "
              ></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WOW from "wow.js";
new WOW().init();
export default {
  name: "AppTalabtkom",

  data() {
    return {
      AppUsers: [
        {
          img: "imCC-sign-up",
          rowDirection: "flex-row",
          title: "CreateAccount",
          text: "CreateAnAccount",
          icon: "iCC-left-side",
          position: "CC-right",
          line: "line-width-first",
          mobreve: "CC-justify-start animate__fadeInLeft",
          animation: "animate__fadeInRight",
        },
        {
          img: "imCC-home",
          rowDirection: "flex-row-reverse",
          title: "favoriteRestaurant",
          text: "availableRestaurants",
          icon: "iCC-right-side",
          position: "CC-left",
          line: "line-width-first",
          mobreve: "CC-justify-end animate__fadeInRight",
          animation: "animate__fadeInLeft",
        },
        {
          img: "imCC-cart",
          rowDirection: "flex-row",
          title: "AddToCart",
          text: "shoppingCart",
          icon: "iCC-left-side",
          position: "CC-right",
          line: "line-width-first",
          mobreve: "CC-justify-start animate__fadeInLeft",
          animation: "animate__fadeInRight",
        },
        {
          img: "imCC-checkout",
          rowDirection: "flex-row-reverse",
          title: "SendOrder",
          text: "completeTheOrder",
          icon: "iCC-right-side",
          position: "CC-left",
          line: "line-width-second",
          mobreve: "CC-justify-end animate__fadeInRight",
          animation: "animate__fadeInLeft",
        },
        {
          img: "imCC-map",
          rowDirection: "flex-row",
          title: "OrderTracking",
          text: "trackTheOrder",
          icon: "iCC-left-side",
          position: "CC-right",
          line: "line-width-second",
          mobreve: "CC-justify-start animate__fadeInLeft",
          animation: "animate__fadeInRight",
        },
        {
          img: "imCC-rate",
          rowDirection: "flex-row-reverse",
          title: "ApplicationEvaluation",
          text: "evaluate",
          icon: "iCC-right-side",
          position: "CC-left",
          line: "line-width-second",
          mobreve: "CC-justify-end animate__fadeInRight",
          animation: "animate__fadeInLeft",
        },
      ],
      newSocialMedia: [
        {
          icon: "iCC-play-store animate__fadeInDown",
          link: "https://play.google.com/store/apps/details?id=com.lundev.talabatcom",
        },
        {
          icon: "iCC-apple-store animate__fadeInDown",
          link: "https://apps.apple.com/us/app/talabatcom/id1612445220",
        },
      ],
    };
  },
};
</script>
<style>
/* Side bar */
.CC-icon-side {
  width: 331px;
  height: 331px;
}
.CC-right {
  right: -170px;
}
.CC-left {
  left: -170px;
}
/* text head */
.CC-broken-line {
  display: block;
}
/* mobile section  */
.CC-justify-start {
  justify-content: start;
}
.CC-justify-end {
  justify-content: end;
}
/* Circle */
.CC-border {
  width: 434px !important;
  height: 434px !important;
}
/* mobile img */
.CC-mobile {
  height: 454px;
  width: 340px;
  bottom: 50px;
}
/* Under Line */
.line-width-first {
  width: 140px;
  height: 2px;
}
.line-width-second {
  width: 80px;
  height: 2px;
}
/* App area text */
.CC-last-app-containt {
  height: 200px;
  font-style: inherit !important;
}

/* App Icons */
.CC-store-last-icon i {
  height: 62px;
  width: 208px;
}
/* Large devices (desktops, less than 1600px) */

@media (max-width: 1600px) {
  /* side bar */
  .CC-right {
    right: -220px;
  }
  .CC-left {
    left: -220px;
  }
  .CC-icon-side {
    display: none;
  }
}
/* Large devices (desktops, less than 1200px) */

@media (max-width: 1200px) {
  /* side bar */
  .CC-right {
    right: none;
  }
  .CC-left {
    left: none;
  }
}
/* Large devices (less than 800px) */
@media (max-width: 800px) {
  /* side Bar */
  .CC-right {
    display: none;
  }
  .CC-left {
    display: none;
  }
  /* head text broken line */
  .CC-broken-line {
    display: inline;
  }
  /* mobile section  */
  .CC-justify-start {
    justify-content: center;
  }
  .CC-justify-end {
    justify-content: center;
  }
  /* mobile img */
  .CC-mobile {
    height: 394px;
    width: 340px;
    bottom: 65px;
  }
  /* circle */
  .CC-border {
    width: 350px !important;
    height: 350px !important;
  }
}
/* Large devices (less than 600px) */
@media (max-width: 600px) {
  .CC-font-title {
    text-align: center !important;
    padding: 20px;
  }
  .CC-last-app-containt {
    height: 200px;
  }
  .CC-store-last-icon i {
    height: 40px;
    width: 100px;
  }
  .CC-border {
    width: 450px !important;
    height: 450px !important;
  }
  .CC-mobile {
    height: 380px;
    width: 340px;
    bottom: 80px;
  }
}
/* Large devices (desktops, less than 500px) */

@media (max-width: 500px) {
  /* circle */
  .CC-border {
    width: 350px !important;
    height: 350px !important;
  }

  .CC-last-app-containt {
    height: 70px;
  }

}

</style>