
import HeaderTalabtkomVue from '@/components/HeaderTalabtkom.vue'
import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'HeaderTalabtkomVue',
    component: HeaderTalabtkomVue,
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
