<template >
  <!-- Choose Section -->

  <div class="container text-center mt-lg-5 mt-3 py-xl-3">
    <!-- Header Text -->
    <div class="CC-header-text mt-5 my-5">
      <p class="fCC-SemiBold-sD cCC-text-secondary py-0 m-2 mt-2">
        {{ $t("why") }} <b class="cCC-text-main"> {{ $t("Talabatcom") }}</b>
      </p>
      <p class="fCC-Regular-sD cCC-opacity-secondary-70 p-0 mt-2">
        {{ $t("enjoy") }}
      <span class="d-block">{{ $t("enjoyand") }}</span>  
      </p>
    </div>

    <!-- Choose Row  -->
    <div class="row flex-lg-row flex-column-reverse  mt-lg-3 mt-xl-3 " :class="$i18n.locale === 'ar' ? 'textend' : 'newdir'">
      <!-- Choose Col -->
      <div
        class="col-lg-3 col-12 mt-md-5"
        v-for="ChooseIcon in ChooseIcons"
        :key="ChooseIcon"
        
      >
        <!-- Choose Card -->
        <div
          class="
            uCC-gap-16
            loop-icons-data
            d-flex
            flex-column
            justify-content-center
            align-items-center
            wow
            animate__animated
          "
          :class="ChooseIcon.animation"
        >
          <!-- Icons -->
          <div class="icon-choose d-flex justify-content-center mt-md-0 mt-3">
            <div
              class="
                CC-choose-circle
                uCC-border-h-50
                d-flex
                justify-content-center
                align-items-center
                position-relative
                cCC-bg-support2
              "
            >
              <i :class="ChooseIcon.icon" class="d-flex iCC-s44"></i>
            </div>
          </div>
          <!-- Section Title  -->
          <div
            class="
              mt-3
              CC-text-choose
              d-flex
              justify-content-center
              text-center
              flex-column
            "
          >
            <p class="fCC-SemiBold-sA m-0">{{ $t(ChooseIcon.ChooseTitle) }}</p>
            <p class="fCC-Regular-sB m-2 p-0 cCC-opacity-secondary-70">
              {{ $t(ChooseIcon.ChooseSubject) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import WOW from "wow.js";
new WOW().init();
export default {
  name: "ChooseTalabtkom",
  props: ["translate"],

  data() {
    return {
      ChooseIcons: [
        {
          
          animation: "animate__fadeInLeft animate__delay-3s",
          icon: "iCC-enjoy ",
          ChooseTitle: "recommendations",
          ChooseSubject: "smartRecommendations",
        },
        {
           
          animation: "animate__fadeInLeft animate__delay-2s",
          icon: "iCC-Restaurant",
          ChooseTitle: "RestaurantsAndMore",
          ChooseSubject: "applicationProvided",
        },
        {
           
          animation: "animate__fadeInLeft animate__delay-1s",
          icon: "iCC-Tracking",
          ChooseTitle: "Tracking",
          ChooseSubject: "map",
        },
        {
           
          animation: "animate__fadeInLeft ",
          icon: "iCC-deliverye",
          ChooseTitle: "quality",
          ChooseSubject: "guarantee",
        },
      ],
    };
  },
};
</script>
<style>
/* Icons  */
.CC-choose-circle i {
  z-index: 2;
}

/* Circle */
.CC-choose-circle {
  width: 135px !important;
  height: 135px !important;
  overflow: hidden;
}
/* Circle before */
.CC-choose-circle::before {
  content: "";
  position: absolute;
  inset: -3px 30px;
  background: red;
  animation: animte 4s linear infinite;
}
@keyframes animte {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
.CC-choose-circle::after {
  border-radius: 50%;
  content: "";
  position: absolute;
  inset: 3px;
  background: inherit;
  z-index: 1;
}
/* Large devices (less than 576px) */
@media (max-width: 575.98px) {
  .CC-text-choose {
    height: 120px !important;
  }
  .CC-header-text {
    position: relative;
    top: 30px;
  }
  /* Circle */
  .CC-choose-circle {
    width: 120px !important;
    height: 120px !important;
    overflow: hidden;
  }
}
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.three {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
</style>