<template>
  <div
    class="
      container
      px-0
      mt-md-5
      pt-5
      d-flex
      position-relative
      justify-content-center
      wow
      animate__animated animate__fadeInLeftBig
     
    "
    
  >
    <!-- <div class="CC-download  d-md-block"> -->
    <!-- Download Image -->
    <i class="imCC-downloadimg imCC-Background-Contain CC-bg-head d-flex" :class="$i18n.locale === 'en' ? 'enreverse' : 'en'" > </i>
    <!-- Download Text -->

    <i
      class="
        imCC-double-mobile
        position-absolute
        imCC-Background-Contain
      "
      :class="$i18n.locale === 'ar' ? 'CC-double-mobile' : 'CC-double-mobile-en'"
    ></i>
    <!-- Images -->
    <i
      class="
        imCC-pizza
        d-flex
        imCC-Background-Contain
       
        position-absolute
      "
      :class="$i18n.locale === 'ar' ? 'CC-pizza' : ' CC-pizza-en'"

    ></i>
    <div
      class="
        
        d-flex
        justify-content-between
        align-items-end
        position-absolute
      "
       :class="$i18n.locale === 'ar' ? 'CC-download-app' : 'CC-download-app-en'"
    >
      <div
        v-for="data in DownloadData"
        :key="data"
        class="
          CC-download-play
          flex-column
          cCC-bg-white
          uCC-border-d-100
          d-flex
          uCC-gap-16
          justify-content-center
          align-items-center
          position-relative
        "
             

      >
        <a
          :href="data.link"
          target="_blank"
          class="
            text-center
            d-flex
            flex-column
            uCC-gap-16
            align-items-center
            text-decoration-none
          "
        >
          <i
            :class="data.icon"
            class="
              d-flex
              iCC-s40
              imCC-Background-Contain
              wow
              animate__animated animate__heartBeat animate__delay-1s
            "
            
          ></i>
          <p
            class="
              d-lg-flex d-none
              fCC-Bold-sA
              cCC-text-secondary
              m-0
              CC-download-font
            "
             
          >
            {{ data.text }}
          </p>
        </a>
        <span class="d-lg-flex d-none CC-star uCC-gap-8 mt-1">
          <i
            class="iCC-star-rate iCC-s12 imCC-Background-Contain d-flex iCC-s12"
            v-for="item in [0, 1, 2, 3, 4]"
            :key="item"
          >
          </i>
        </span>
      </div>
    </div>

    <i
      class="
        imCC-flower
        d-flex
        imCC-Background-Contain
        
        position-absolute
      "
      :class="$i18n.locale === 'ar' ? 'CC-flower' : 'CC-flower-en'"
    ></i>
    <!--Text download section  -->
    <p
      class="
        m-0
        fCC-SemiBold-sC
        cCC-text-white
        CC-download-text
        position-absolute
        d-flex
        h-50
        align-items-center
        
        cCC-text-secondary
      "
    :class="$i18n.locale === 'ar' ? 'CC-download-text' : 'CC-download-text-en'"
    >
    
      {{ $t("UploadTalabatcom") }}
    </p>

    <!-- Download (App Store , Google Play ) -->
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "DownloadTalabtkom",
  data() {
    return {
      DownloadData: [
        {
          icon: "iCC-google-play",
          text: "Google Play",
          link: "https://play.google.com/store/apps/details?id=com.lundev.talabatcom",
        },
        {
          icon: "iCC-app-store",
          text: "App Store",
          link: "https://apps.apple.com/us/app/talabatcom/id1612445220",
        },
      ],
    };
  },
};
</script>
<style>

.enreverse{
  transform: scaleX(-1);
}
.CC-download {
  height: 330px;
  width: 1050px;
}
.CC-bg-head {
  height: 345px;
  width: 100%;
}
.CC-double-mobile {
  width: 460px;
  height: 480px;
  bottom: 10px;
  left: 10px;
}

.CC-pizza {
  transform: rotate(2deg);
  width: 219px;
  height: 220px;
  right: 80px;
  top: -56px;
}

.CC-download-app {
  width: 270px;
  left: 520px;
  bottom: 0;
}
.CC-download-play {
  width: 124px;
  height: 175px;
}

.CC-flower {
  width: 92px;
  height: 88px;
  bottom: -40px;
  left: 0px;
  z-index: -1;
}

.CC-download-text {
  width: 382px;
  right: 5%;
  bottom: 50px;
  text-align: end;
  font-style: normal;
}


.CC-download-font {
  font-style: normal;
}
.CC-download-text-en {
  width: 382px;
  left: 5%;
  bottom: 50px;
  font-style: normal;
  text-align: start;
}
.CC-flower-en {
  width: 92px;
  height: 88px;
  bottom: -40px;
  right: 0px;
  z-index: -1;
}
.CC-pizza-en {
  transform: rotate(8deg);
  width: 219px;
  height: 220px;
  left: 80px;
  top: -56px;
}
.CC-double-mobile-en {
  width: 460px;
  height: 480px;
  bottom: 10px;
  right: 10px;
}
 .CC-download-app-en {
    width: 270px;
    left: 460px;
    bottom: 0;
  }
/*media query  */

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .CC-bg-head {
    height: 100px;
    width: 100%;
  }
  .CC-double-mobile {
    width: 160px;
    height: 160px;
    bottom: 40px;
    left: 20px;
  }
  .CC-pizza {
    transform: rotate(1deg);
    width: 80px;
    top: -60px;
    right: 31px;
  }
  .CC-download-app {
    width: 100px;
    left: 120px;
    bottom: 0px;
  }
  .CC-download-play {
    width: 40px;
    height: 50px;
  }
  .CC-download-text {
      width: 150px;
    height: 80px !important;
    right: 103px;
    bottom: -5px;
    font-style: normal;
    
  }
  .CC-flower {
    height: 40px;
    bottom: -20px;
    left: 0px;
    z-index: -1;
  }
  .CC-download-text-en {
  width: 140px;
  left: 8%;
  bottom: -5px;
  font-style: normal;
  text-align: start;
}
.CC-flower-en {
  width: 52px;
  height: 40px;
  bottom: -20px;
  right: 0px;
  z-index: -1;
}
.CC-pizza-en {
transform: rotate(8deg);
    width: 75px;
    height: 165px;
    left: 63px;
    top: -31px;
}
.CC-double-mobile-en {
  width: 130px;
  height: 180px;
  bottom: 10px;
  right: 8px;
}
 .CC-download-app-en {
    width: 100px;
    left: 160px;
    bottom: 0;
  }
}

/* // Extra small devices (portrait phones, less than 400px) */
@media (max-width: 389px) {
  .CC-double-mobile {
    width: 160px;
    height: 160px;
    bottom: 30px;
    left: 0px;
  }
  .CC-pizza {
    transform: rotate(1deg);
    width: 60px;
    top: -55px;
    right: 20px;
  }
  .CC-download-app {
    width: 100px;
    left: 105px;
    bottom: 0px;
  }
  .CC-download-play {
    width: 40px;
    height: 50px;
  }
  .CC-download-text {
    width: 132px;
    height: 80px !important;
    right: 1%;
    bottom: 0px;
    font-style: normal;
  }
  .CC-flower {
    height: 40px;
    bottom: -20px;
    left: 0px;
    z-index: -1;
  }
    .CC-download-text-en {
  width: 125px;
  left: 2%;
  bottom: -5px;
  font-style: normal;
  text-align: start;
}
.CC-flower-en {
  width: 42px;
  height: 40px;
  bottom: -20px;
  right: 0px;
  z-index: -1;
}
.CC-pizza-en {
  transform: rotate(8deg);
  width: 70px;
  height: 230px;
  left: 30px;
  top: -60px;
}
.CC-double-mobile-en {
  width: 130px;
  height: 180px;
  bottom: 10px;
  right: 10px;
}
 .CC-download-app-en {
    width: 90px;
    left: 140px;
    bottom: 0;
  }
}

/* // Extra small devices (portrait phones, less than 340px) */
@media (max-width: 340px) {
  .CC-double-mobile {
    width: 130px;
    height: 140px;
    bottom: 30px;
  }
  .CC-pizza {
    transform: rotate(1deg);
    width: 60px;
    top: -53px;
    right: 20px;
  }
  .CC-download-app {
    width: 90px;
    left: 100px;
    bottom: 0px;
  }
  .CC-download-play {
    width: 40px;
    height: 50px;
  }
  .CC-download-text {
    width: 90px;
    height: 80px !important;
    right: 5%;
    bottom: 5px;
    font-style: normal;
  }
  .CC-flower {
    height: 40px;
    bottom: -10px;
    left: 0px;
    z-index: -1;
  }
    .CC-download-text-en {
  width: 100px;
  left: 2%;
  bottom: 0px;
  font-style: normal;
  text-align: start;
}
.CC-flower-en {
  width: 32px;
  height: 40px;
  bottom: -10px;
  right: 0px;
  z-index: -1;
}
.CC-pizza-en {
  transform: rotate(8deg);
  width: 60px;
  height: 220px;
  left: 40px;
  top: -50px;
}
.CC-double-mobile-en {
  width: 120px;
  height: 140px;
  bottom: 10px;
  right: 10px;
}
 .CC-download-app-en {
    width: 100px;
    left: 120px;
    bottom: 0;
  }
}
@media (max-width: 325px) {
  .CC-download-text {
    width: 100px;
    height: 80px !important;
    right: 5%;
    bottom: 0px;
    font-style: normal;
  }
  .CC-double-mobile-en {
  width: 100px;
  height: 140px;
  bottom: 10px;
  right: 10px;
}
   .CC-download-app-en {
    width: 85px;
    left: 100px;
    bottom: 0;
  }
}
@media (max-width: 429px) {
  .CC-download-text {
    right: 7% !important;
  }
}
/* // Small devices (landscape phones, 767px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .CC-double-mobile {
    width: 200px;
    height: 280px;
    bottom: 100px;
  }

  .CC-pizza {
    transform: rotate(1deg);
    width: 100px;
    top: 40px;
    right: 20px;
  }
  .CC-download-app {
    width: 100px;
    left: 180px;
    bottom: 90px;
  }
  .CC-download-play {
    width: 40px;
    height: 60px;
  }
  .CC-download-text {
    width: 200px;
    height: 80px !important;
    right: 5%;
    bottom: 50px;
    font-style: normal;
  }
  .CC-flower {
    height: 40px;
    bottom: 70px;
    left: 0px;
    z-index: -1;
  }
   .CC-download-app-en {
    width: 100px;
    left: 160px;
    bottom: 0;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .CC-double-mobile {
    width: 260px;
    height: 280px;
    bottom: 100px;
  }
  .CC-pizza {
    transform: rotate(1deg);
    width: 120px;
    top: 20px;
  }
  .CC-download-app {
    width: 220px;
    left: 250px;
    bottom: 0;
  }
  .CC-download-play {
    width: 100px;
    height: 175px;
  }
  .CC-download-text {
    width: 200px;
    right: 5%;
    bottom: 50px;
    font-style: normal;
  }
  .CC-flower {
    height: 60px;
    bottom: 40px;
    left: 0px;
    z-index: -1;
  }
  .CC-download-text-en {
  width: 220px;
  left: 5%;
  bottom: 50px;
  font-style: normal;
  text-align: start;
}
.CC-flower-en {
  width: 92px;
  height: 88px;
  bottom: 20px;
  right: 0px;
  z-index: -1;
}
.CC-pizza-en {
  transform: rotate(8deg);
  width: 119px;
  height: 120px;
  left: 80px;
  top: 75px;
}
.CC-double-mobile-en {
  width: 250px;
  height: 380px;
  bottom: 40px;
  right: 10px;
}
 .CC-download-app-en {
    width: 220px;
    left: 260px;
    bottom: 0;
  }
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .CC-double-mobile {
    width: 340px;
    height: 400px;
    bottom: 100px;
  }
  .CC-pizza {
    transform: rotate(1deg);
    width: 160px;
    height: 220px;
    top: -12px;
  }
  .CC-download-app {
    width: 270px;
    left: 250px;
    bottom: 0;
  }
  .CC-download-text-en {
  width: 322px;
  left: 5%;
  bottom: 30px;
  font-style: normal;
  text-align: start;
}
.CC-flower-en {
  width: 92px;
  height: 88px;
  bottom: -10px;
  right: 0px;
  z-index: -1;
}
.CC-pizza-en {
  transform: rotate(8deg);
  width: 219px;
  height: 220px;
  left: 80px;
  top: -9px;
}
.CC-double-mobile-en {
  width: 360px;
  height: 380px;
  bottom: 70px;
  right: 10px;
}
  .CC-download-app-en {
    width: 270px;
    left: 360px;
    bottom: 0;
  }
   .CC-download-app-en {
    width: 260px;
    left: 360px;
    bottom: 0;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .CC-pizza {
      transform: rotate(2deg);
    width: 208px;
    height: 220px;
    top: -54px

  }
    .CC-pizza-en {
    
    width: 160px;
    height: 175px;
    top: -35px;
  }
}
</style>
