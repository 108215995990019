<template >
  <div class="CC-flag" @click="updateLanguage()" :value="this.$i18n.locale">
    <a
      href="#"
      class="CC-lang cCC-text-main fCC-SemiBold-sA"
      @click="setLocale('ar')"
      ><p  v-if="this.$i18n.locale =='en' ">العربية</p></a
    >
    <a
      href="#"
      class="CC-lang cCC-text-main fCC-SemiBold-sA"
      @click="setLocale('en')"
      ><p   v-if="this.$i18n.locale =='ar' ">English</p></a
    >
  </div>

  <div class="CC-new" dir="ltr">
    <HeaderTalabtkom />
    <div class="py-lg-5 position-relative mb-5">
      <div class="side-icon position-absolute CC-position-chili">
        <i class="iCC-Chili d-flex CC-bird-holder imCC-Background-Contain"></i>
      </div>
      <div class="side-icon position-absolute CC-position-green end-0">
        <i class="iCC-green d-flex CC-green-holder imCC-Background-Contain"></i>
      </div>

      <ChooseTalabtkom class="my-5" />
    </div>
    <div class="my-lg-5 my-4 mt-5">
      <DownloadTalabtkom />
    </div>
    <div class="my-lg-5">
      <AppTalabtkom />
    </div>
    <FooterTalabtkom />
    <ModalForm />
  </div>
</template>

<script>
import HeaderTalabtkom from "./components/HeaderTalabtkom.vue";
import ChooseTalabtkom from "./components/ChooseTalabtkom.vue";
import DownloadTalabtkom from "./components/DownloadTalabtkom.vue";
import AppTalabtkom from "./components/AppTalabtkom.vue";
import FooterTalabtkom from "./components/FooterTalabtkom.vue";
import ModalForm from "./components/ModalForm.vue";

export default {
  name: "App",
  data() {
    return {
      active: true,
      direction: "CC-new",
      name: "",
      locales: ["ar", "en"],
    };
  },
  components: {
    HeaderTalabtkom,
    ChooseTalabtkom,
    DownloadTalabtkom,
    AppTalabtkom,
    FooterTalabtkom,
    ModalForm,
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
  
    updateLanguage() {
      localStorage.setItem("locale", this.$i18n.locale);
    },
  },
  mounted() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
    } else {
      localStorage.setItem("locale", this.$i18n.locale);
    }
  },
};

</script>

<style>
#app {
  display: flex;
  flex-direction: column;
}
.CC-bird-holder {
  width: 300px;
  height: 225px;
}
.CC-position-chili {
  left: -120px;
  top: 100px;
}
.CC-position-green {
  top: 450px;
}
.CC-green-holder {
  width: 46px;
  height: 49px;
}
.CC-flag {
  position: absolute;
  z-index: 3;
  width: 10vw;
  height: 10vh;
  left: 235px;
  top: 25px;
  opacity: 70%;
  justify-content: space-around;
}
.CC-flag a .as {
  width: 40px;
  height: 35px;
}
.CC-lang {
  text-decoration: none;
}
.CC-lang:hover {
  color: black;
}
.newdir {
  direction: rtl;
}
.modaldir {
  justify-content: end;
  direction: ltr;
}
.textdir {
  direction: ltr;
  text-align: start;
}
@media (max-width: 1300px) {
  .CC-position-chili {
    display: none;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .CC-flag {
    left: 25px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .CC-flag {
    left: 25px;
  }
}
@media (width: 820px) {
  .CC-flag {
    left: 50px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .CC-flag {
    left: 135px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .CC-flag {
    position: absolute;
    z-index: 3;
    width: 10vw;
    height: 10vh;
    left: 35px;
    top: 25px;
    opacity: 70%;
    justify-content: space-around;
  }
}
</style>
